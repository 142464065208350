:root {
  --primary-color: #008b8b;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  // Header
  .searchHeader {
    .searchItems {
      padding: 12px;
      border-radius: 8px;
      display: flex;
      gap: 8px;
      align-items: center;
      background-color: var(--primary-color);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .search {
        width: 100%;
        position: relative;
        border-radius: 8px;

        .searchButton {
          height: 100%;
          position: absolute;
          right: 0;
          border: 1px solid #fff;
          border-radius: 2px;
          top: 0px;
          cursor: pointer;
        }
      }
    }
    .totalCount {
      color: black;
      font-weight: 500;
      text-align: right;
      margin-top: 8px;
      font-size: 16px;
    }
  }
  // Main
  .main {
    // overflow: auto;
    // scrollbar-width: none; /* Firefox */
    // -ms-overflow-style: none; /* IE */
    // &::-webkit-scrollbar {
    //   /* WebKit */
    //   width: 0;
    //   height: 0;
    // }
    margin-top: 12px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 12px;

    // Card
    .card {
      border-radius: 12px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      min-height: 185px;
      max-height: 185px;
    }
  }
}

@media screen and (max-width: 768px) {
  .searchItems {
    padding: 12px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    background-color: var(--primary-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}