@include device-type(not-pc) {
  .ant-modal-content {
    border-radius: 12px 12px 0 0;

    .ant-modal-header {
      border-radius: 12px 12px 0 0;
    }
    .ant-modal-close {
      border-radius: 0 12px 0 0;
    }

    .ant-table table {
      table-layout: unset !important;
    }
  }
  .ant-modal-wrap {
    top: unset;
    height: 100%;
  }
  .ant-modal {
    top: unset;
    bottom: 0;
    padding-bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin-bottom: 0;
    transform-origin: unset !important;
    width: 100%;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.ant-modal .rpv-default-layout-container {
  @include device-type(not-pc) {
    padding-top: 40px;
    border: none;
  }
  @include device-type(mb) {
    width: fit-content;
  }
}

@include device-type(not-pc) {
  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column;
  }
}

.ant-modal .ant-modal-body {
  @include device-type(not-pc) {
    overflow-y: auto;
    padding: 12px;
    @media screen and (orientation: landscape) {
      max-height: 250px;
    }
  }
  @include device-type(mb) {
    max-height: 550px;
  }
  @include device-type(tb) {
    max-height: 700px;
  }
}

// Если в модальном окне используется DraggableModal, 
// то отображаем содержимое модального окна всегда, 
// чтобы всегда было видно resize-handle
.ant-design-draggable-modal {
  .ant-modal {
    overflow: visible;
  }
}

// TASK MODAL
.create-modal-in-drawer {

  .headerCalendarTaskDrawer {
    @include device-type(not-pc) {
      background-color: white;
      .ant-form-vertical .ant-form-item-label > label {
        color: black !important;
      }
    }
  }
  .BodyCalendarTaskDrawer {
    @include device-type(not-pc) {
      padding: 0 15px 15px;
    }
    @include device-type(pc) {
      padding: 30px;
    }
  }
  @include device-type(not-pc) {
    .tabs {
      display: none;
    }
  }
  .EditForm-task-status-switcher {
    @include device-type(not-pc) {
      width: 100%;
      margin-bottom: 20px;

      .ant-radio-button-wrapper {
        border-radius: 4px !important;
        line-height: 20px;
        border: none;
        span {
          white-space: nowrap !important;
        }

        &:not(.ant-radio-button-wrapper-checked) {
          background-color: #f4f9fd;
        }

        &:before {
          display: none;
        }
        +.ant-radio-button-wrapper {
          margin-left: 10px;
        }

        span {
          font-size: 10px;
        }
      }
    }
  }

  .ant-drawer-content-wrapper {
    height: 100% !important;
    margin: 0 auto !important;
    right: 0 !important;
    top: 10% !important;
    bottom: 0 !important;
    left: 0 !important;
    @include device-type(not-pc) {
      width: 100% !important;
    }
    @include device-type(pc) {
      width: 1200px !important;
    }
  }
  .main-info {
    @include device-type(not-pc) {
      padding: 15px;
    }
  }
  .ant-col {
    @include device-type(not-pc) {
      width: 100% !important;
      max-width: 100%;
      color: black !important;
    }
  }
  @include device-type(not-pc) {
    .ant-col-5 {
      flex: unset;
    }
  }
  .EditForm-task-status .ant-select-selector {
    @include device-type(not-pc) {
      width: 100% !important;
      min-width: 300px;
    }
  }
}

@include device-type(not-pc) {
  .notification-drawer-list .ant-row {
    flex-wrap: nowrap;
    gap: 10px;
  }

  .CustomerTable-create-dgv-modal {
    .ant-modal-footer {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
        margin-left: 0 !important;
      }
    }
  }
}

// STYLES KP PREVIEW
//.ant-modal-content .view-CP {
//  @include device-type(not-pc) {
//    max-width: 100%;
//    width: 100%;
//  }
//
//  .u-row .u-col {
//    @include device-type(mb) {
//      min-width: 100% !important;
//      max-width: 100% !important;
//
//      div {
//        max-width: 100% !important;
//        width: 100% !important;
//      }
//      td {
//        min-width: unset !important;
//        max-width: 100% !important;
//      }
//    }
//  }
//}
